import ContactUsPage from "./pages/ContactUsPage";
import HomePage from "./pages/HomePage";
import { Route, Routes } from "react-router-dom";

export const HOME = "/";
export const CONTACT_US = "/contact-us";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path={HOME} element={<HomePage />} />
        <Route path={CONTACT_US} element={<ContactUsPage />} />
        {/* 404 */}
        <Route path="*" element={<HomePage />} />
      </Routes>
    </div>
  );
}

export default App;
