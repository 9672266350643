import { useEffect, useRef, useState } from "react";
import { Dialog, Disclosure } from "@headlessui/react";
import {
  Bars3Icon,
  BoltIcon,
  DevicePhoneMobileIcon,
  EyeIcon,
  LockClosedIcon,
  PuzzlePieceIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { ChevronRightIcon, StarIcon } from "@heroicons/react/20/solid";
import { motion, AnimatePresence, useAnimation } from "framer-motion";
import {
  ArrowPathIcon,
  CloudArrowUpIcon,
  FingerPrintIcon,
  CogIcon,
  ClockIcon,
} from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { CONTACT_US, HOME } from "../App";
import { MinusSmallIcon, PlusSmallIcon } from "@heroicons/react/24/outline";
import Kyle from "../assets/Kyle Hughes.jpg";

const faqs = [
  {
    question: "How can you build a website with so few meetings?",
    answer:
      "We tailor our solutions to fit our clients, for customers who value speed and want a more hands off approach, we support them through minimizing meeting times and communicating through slack, email, or text.",
  },
  {
    question: "I want to have a more hands on approach, is that possible?",
    answer:
      "Absolutely, we are happy to meet as frequently or infrequently as you like. We can also provide you with a staging environment so you can see the progress of your website as it is being built.",
  },
  {
    question: "Do you do web apps or just websites?",
    answer:
      "We can build custom web applications and integrate them with many third party services, such as stripe, google maps, chatgpt and many more.",
  },
  {
    question: "What is your pricing?",
    answer:
      "We can't provide generalized pricing as all quotes are on a per project basis. Typically we will charge an amount for the project development, then a fixed monthly price for hosting and maintenance.",
  },
];

const features = [
  {
    name: "Hands off process",
    description:
      "With just a few meetings we can design build and deploy your website.",
    icon: CogIcon,
  },
  {
    name: "Fast turn arounds",
    description: "We average a 7 day turn around on standard website builds.",
    icon: ClockIcon,
  },
  {
    name: "Responsive websites",
    description:
      "Our websites use hundreds of global servers to ensure your website loads lightning fast.",
    icon: ArrowPathIcon,
  },
  {
    name: "Advanced security",
    description:
      "We use industry standard security to ensure your website is protected from bad actors.",
    icon: LockClosedIcon,
  },
];

const features2 = [
  {
    name: "Functional Design",
    description:
      "We craft visually appealing, practical websites with user-friendly navigation, intuitive interfaces, and seamless interactions.",
    icon: PuzzlePieceIcon,
  },
  {
    name: "Accessible for All",
    description:
      "Our service ensures websites are accessible to all, adhering to WCAG guidelines for a universally user-friendly experience.",
    icon: EyeIcon,
  },
  {
    name: "Fast Load Times",
    description:
      "We enhance your site's speed with optimized images and streamlined code, ensuring quick load times for better user satisfaction and SEO.",
    icon: BoltIcon,
  },
  {
    name: "Responsive Design",
    description:
      "Our designs guarantee a flawless, adaptable experience on all devices, with flexible layouts and responsive media queries.",
    icon: DevicePhoneMobileIcon,
  },
];

export default function HomePage() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <div className="bg-white">
      <header>
        <div className="relative bg-white">
          <div className="max-w-7xl mx-auto px-4 sm:px-6">
            <div className="flex justify-between items-center border-b-2 border-gray-100 py-6 md:justify-start md:space-x-10">
              <Link
                to={HOME}
                className="max-w-lg text-xl sm:text-left font-bold font-jakarta-sans tracking-tight text-gray-900 sm:text-xl"
              >
                SwiftWeb<span className="text-red-400 tracking-tight">.io</span>
              </Link>
            </div>
          </div>
        </div>
      </header>

      {/* Mobile Section */}
      <div className="relative isolate pt-14">
        <svg
          className="absolute inset-0 -z-10 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="83fd4e5a-9d52-42fc-97b6-718e5d7ee527"
              width={200}
              height={200}
              x="50%"
              y={-1}
              patternUnits="userSpaceOnUse"
            >
              <path d="M100 200V.5M.5 .5H200" fill="none" />
            </pattern>
          </defs>
          <svg x="50%" y={-1} className="overflow-visible fill-red-200">
            <path
              d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
              strokeWidth={0}
            />
          </svg>
          <svg x="50%" y={-1} className="overflow-visible fill-red-200">
            <path
              d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
              strokeWidth={0}
            />
          </svg>
          <rect
            width="100%"
            height="100%"
            strokeWidth={0}
            fill="url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)"
          />
        </svg>
        <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:flex lg:items-center lg:gap-x-10 lg:px-8 lg:py-40">
          <div className="mx-auto max-w-2xl lg:mx-0 lg:flex-auto">
            <div className="flex"></div>
            <h1 className="mt-10 max-w-lg text-4xl text-center sm:text-left font-bold font-jakarta-sans tracking-tight text-gray-900 sm:text-6xl">
              Custom Websites done right
            </h1>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              Interested in learning how we can help? Book a free consultation
              today below.
            </p>
            <div className="mt-10 flex items-center gap-x-6">
              <Link
                to={CONTACT_US}
                className="rounded-md mx-auto lg:mx-0 bg-red-600 px-3.5 py-2.5 text-md font-semibold text-white shadow-sm hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
              >
                Book your free consultancy now
              </Link>
            </div>
          </div>
          <div className="mt-16 sm:mt-24 lg:mt-0 lg:flex-shrink-0 lg:flex-grow">
            {/* Mobile Website Image */}

            <svg
              initial={{ y: 200, opacity: 0.5 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.2 }}
              viewBox="0 0 366 729"
              role="img"
              className="mx-auto w-[22.875rem] max-w-full drop-shadow-xl"
            >
              <title>App screenshot</title>
              <defs>
                <clipPath id="2ade4387-9c63-4fc4-b754-10e687a0d332">
                  <rect width={316} height={684} rx={36} />
                </clipPath>
              </defs>
              <path
                fill="#4B5563"
                d="M363.315 64.213C363.315 22.99 341.312 1 300.092 1H66.751C25.53 1 3.528 22.99 3.528 64.213v44.68l-.857.143A2 2 0 0 0 1 111.009v24.611a2 2 0 0 0 1.671 1.973l.95.158a2.26 2.26 0 0 1-.093.236v26.173c.212.1.398.296.541.643l-1.398.233A2 2 0 0 0 1 167.009v47.611a2 2 0 0 0 1.671 1.973l1.368.228c-.139.319-.314.533-.511.653v16.637c.221.104.414.313.56.689l-1.417.236A2 2 0 0 0 1 237.009v47.611a2 2 0 0 0 1.671 1.973l1.347.225c-.135.294-.302.493-.49.607v377.681c0 41.213 22 63.208 63.223 63.208h95.074c.947-.504 2.717-.843 4.745-.843l.141.001h.194l.086-.001 33.704.005c1.849.043 3.442.37 4.323.838h95.074c41.222 0 63.223-21.999 63.223-63.212v-394.63c-.259-.275-.48-.796-.63-1.47l-.011-.133 1.655-.276A2 2 0 0 0 366 266.62v-77.611a2 2 0 0 0-1.671-1.973l-1.712-.285c.148-.839.396-1.491.698-1.811V64.213Z"
              />
              <path
                fill="#343E4E"
                d="M16 59c0-23.748 19.252-43 43-43h246c23.748 0 43 19.252 43 43v615c0 23.196-18.804 42-42 42H58c-23.196 0-42-18.804-42-42V59Z"
              />
              <foreignObject
                width={316}
                height={684}
                transform="translate(24 24)"
                clipPath="url(#2ade4387-9c63-4fc4-b754-10e687a0d332)"
              >
                {/* Mobile Image here */}
                <img
                  src="https://tailwindui.com/img/component-images/mobile-app-screenshot.png"
                  alt=""
                />
              </foreignObject>
            </svg>
          </div>
        </div>
      </div>

      {/* Features */}
      <div className="bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:text-center">
            <h2 className="text-base font-semibold leading-7 text-red-600">
              Speed and simplicity
            </h2>
            <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              From Design to Development, Implementation and Maintainence.
            </p>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              Our end to end solution covers all your business needs. Design,
              copywriting, development, and maintainence. We do the whole
              process end to end so you can be sure your business gets the
              results it needs.
            </p>
          </div>
          <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
            <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
              {features.map((feature) => (
                <div key={feature.name} className="relative pl-16">
                  <dt className="text-base font-semibold leading-7 text-gray-900">
                    <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-red-200">
                      <feature.icon
                        className="h-6 w-6 text-red-800"
                        aria-hidden="true"
                      />
                    </div>
                    {feature.name}
                  </dt>
                  <dd className="mt-2 text-base leading-7 text-gray-600">
                    {feature.description}
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>

      {/* More Features */}

      <div className="overflow-hidden bg-gray-50 py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
            <div className="lg:ml-auto lg:pl-4 lg:pt-4">
              <div className="lg:max-w-lg">
                <h2 className="text-base font-semibold leading-7 text-red-600">
                  Professional Design
                </h2>
                <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                  Industry leading design to suit your customers needs
                </p>
                <p className="mt-6 text-lg leading-8 text-gray-600">
                  Here at SwiftWeb we pride ourselves on our design. We use a
                  range of tools to ensure that your website is not only
                  beautiful but also functional.
                </p>
                <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                  {features2.map((feature) => (
                    <div key={feature.name} className="relative pl-10">
                      <div className="mb-2">
                        {" "}
                        {/* Margin bottom for spacing between name and description */}
                        <dt className="font-semibold text-gray-900">
                          <div className="absolute left-0 top-0 flex h-8 w-8 items-center justify-center rounded-lg bg-red-200">
                            <feature.icon
                              className="absolute h-5 w-5 text-red-600"
                              aria-hidden="true"
                            />
                          </div>
                          {feature.name}
                        </dt>
                      </div>
                      <dd>{feature.description}</dd>
                    </div>
                  ))}
                </dl>
              </div>
            </div>
            <div className="flex items-start justify-end lg:order-first">
              <img
                src="https://tailwindui.com/img/component-images/dark-project-app-screenshot.png"
                alt="Product screenshot"
                className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem]"
                width={2432}
                height={1442}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Stats */}
      {/* <div className="bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              We approach the workplace as something that adds to our lives and
              adds value to world.
            </h2>
            <p className="mt-6 text-base leading-7 text-gray-600">
              Diam nunc lacus lacus aliquam turpis enim. Eget hac velit est
              euismod lacus. Est non placerat nam arcu. Cras purus nibh cursus
              sit eu in id. Integer vel nibh.
            </p>
          </div>
          <div className="mx-auto mt-16 flex max-w-2xl flex-col gap-8 lg:mx-0 lg:mt-20 lg:max-w-none lg:flex-row lg:items-end">
            <div className="flex flex-col-reverse justify-between gap-x-16 gap-y-8 rounded-2xl bg-gray-50 p-8 sm:w-3/4 sm:max-w-md sm:flex-row-reverse sm:items-end lg:w-72 lg:max-w-none lg:flex-none lg:flex-col lg:items-start">
              <p className="flex-none text-3xl font-bold tracking-tight text-gray-900">
                250k
              </p>
              <div className="sm:w-80 sm:shrink lg:w-auto lg:flex-none">
                <p className="text-lg font-semibold tracking-tight text-gray-900">
                  Users on the platform
                </p>
                <p className="mt-2 text-base leading-7 text-gray-600">
                  Vel labore deleniti veniam consequuntur sunt nobis.
                </p>
              </div>
            </div>
            <div className="flex flex-col-reverse justify-between gap-x-16 gap-y-8 rounded-2xl bg-gray-900 p-8 sm:flex-row-reverse sm:items-end lg:w-full lg:max-w-sm lg:flex-auto lg:flex-col lg:items-start lg:gap-y-44">
              <p className="flex-none text-3xl font-bold tracking-tight text-white">
                $8.9 billion
              </p>
              <div className="sm:w-80 sm:shrink lg:w-auto lg:flex-none">
                <p className="text-lg font-semibold tracking-tight text-white">
                  We’re proud that our customers have made over $8 billion in
                  total revenue.
                </p>
                <p className="mt-2 text-base leading-7 text-gray-400">
                  Eu duis porta aliquam ornare. Elementum eget magna egestas.
                </p>
              </div>
            </div>
            <div className="flex flex-col-reverse justify-between gap-x-16 gap-y-8 rounded-2xl bg-cyan-900 p-8 sm:w-11/12 sm:max-w-xl sm:flex-row-reverse sm:items-end lg:w-full lg:max-w-none lg:flex-auto lg:flex-col lg:items-start lg:gap-y-28">
              <p className="flex-none text-3xl font-bold tracking-tight text-white">
                401,093
              </p>
              <div className="sm:w-80 sm:shrink lg:w-auto lg:flex-none">
                <p className="text-lg font-semibold tracking-tight text-white">
                  Transactions this year
                </p>
                <p className="mt-2 text-base leading-7 text-cyan-200">
                  Eu duis porta aliquam ornare. Elementum eget magna egestas. Eu
                  duis porta aliquam ornare.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* CTA consulting */}
      <div className="bg-gray-50">
        <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:flex lg:items-center lg:justify-between lg:px-8">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Ready to get started?
            <br />
            Book your free consultancy now.
          </h2>
          <div className="mx-auto lg:mx-0 mt-10 lg:mt-0 lg:flex-shrink-0 flex justify-center lg:justify-start">
            <Link
              to={CONTACT_US}
              className="rounded-md bg-red-600 px-12 py-3 text-lg font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
            >
              Let's do it
            </Link>
          </div>
        </div>
      </div>

      {/* Testimonial */}
      <section className="bg-white px-6 py-24 sm:py-32 lg:px-8">
        <figure className="mx-auto max-w-2xl">
          <p className="sr-only">5 out of 5 stars</p>
          <div className="flex gap-x-1 text-cyan-600">
            <StarIcon className="h-5 w-5 flex-none" aria-hidden="true" />
            <StarIcon className="h-5 w-5 flex-none" aria-hidden="true" />
            <StarIcon className="h-5 w-5 flex-none" aria-hidden="true" />
            <StarIcon className="h-5 w-5 flex-none" aria-hidden="true" />
            <StarIcon className="h-5 w-5 flex-none" aria-hidden="true" />
          </div>
          <blockquote className="mt-10 text-xl font-semibold leading-8 tracking-tight text-gray-900 sm:text-2xl sm:leading-9">
            <div className="flex flex-col items-center">
              <span className="self-start text-4xl sm:text-5xl font-serif text-gray-400">
                “
              </span>
              <p className="px-4">
                Working with the SwiftWeb team has been a pleasure for our company.
                The lead developer Ryans explanations were clear, I felt like he understood our needs
                throughout the entire development cycle. Their planning was
                sound, the project was delivered within budget and in a timely
                manner.
                <br />
                <br />
                The quality of the work was consistently above expectations, and
                we're extremely pleased with our outcome. I wouldn't hesitate to
                continue working with the SwiftWeb team into the future.
              </p>
              <span className="self-end text-4xl sm:text-5xl font-serif text-gray-400">
                ”
              </span>
            </div>
          </blockquote>

          <figcaption className="mt-10 flex items-center gap-x-6">
            <img
              className="h-12 w-12 rounded-full bg-gray-50"
              src={Kyle}
              alt=""
            />
            <div className="text-sm leading-6">
              <div className="font-semibold text-gray-900">Kyle Hughes</div>
              <div className="mt-0.5 text-gray-600">
                Owner and Director of Atlas Tech Solutions
              </div>
            </div>
          </figcaption>
        </figure>
      </section>

      {/* FAQ */}
      <div className="bg-white">
        <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:px-8 lg:py-40">
          <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
            <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">
              Frequently asked questions
            </h2>
            <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
              {faqs.map((faq) => (
                <Disclosure as="div" key={faq.question} className="pt-6">
                  {({ open }) => (
                    <>
                      <dt>
                        <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                          <span className="text-base font-semibold leading-7">
                            {faq.question}
                          </span>
                          <span className="ml-6 flex h-7 items-center">
                            {open ? (
                              <MinusSmallIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            ) : (
                              <PlusSmallIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            )}
                          </span>
                        </Disclosure.Button>
                      </dt>
                      <Disclosure.Panel as="dd" className="mt-2 pr-12">
                        <p className="text-base leading-7 text-gray-600">
                          {faq.answer}
                        </p>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              ))}
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
}
